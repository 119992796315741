import React from 'react';
import { Link } from 'gatsby';

import '@css/tailwind.css';
import '@css/main.css';

const ContestStart = () => {
  const downloadMultiplePDFs = () => {
    const pdfUrls = [
      'https://cdn.sanity.io/files/dogza56w/production/3c341715cac0cd1c9174d94f19788eb5c6afa757.pdf',
      'https://cdn.sanity.io/files/dogza56w/production/d83a4c7546ad1a134abb0c17f034e65fafdf8db6.pdf',
      'https://cdn.sanity.io/files/dogza56w/production/c56d8fed8988c0319da25a428e1de18d759c8d62.pdf',
      'https://cdn.sanity.io/files/dogza56w/production/11e6ae031ce0596c0a1e69c058a72a9c71087516.pdf',
    ];

    pdfUrls.forEach((url) => {
      window.open(url, '_blank');
    });
  };

  return (
    <div className='entry-form-container w-full h-full min-h-screen pb-20 '>
      <Link
        to='/'
        className='btn-entry absolute top-11 sm:top-9  xl:top-20  right-gutter  '>
        Go Home
      </Link>
      <div className=' pt-[46px] sm:pt-[36px] xl:pt-[77px] pb-[44px] sm:pb-[60px] xl:pb-[146px]'>
        <h3 className='blockH6 mb-[30px] '>NZCT Chamber Music Contest</h3>
        <h2 className='blockH1'>2024 Entry Form</h2>
      </div>
      <div className='border-t border-sand pt-14'>
        <div className='max-w-[550px] mb-[44px] sm:mb-[60px] xl:mb-[88px]'>
          <h3 className='blockH4 mb-3'>Select Entry Type</h3>
          <p className='blockH8'>
            Please select from the entry types below. All entries are to be
            submitted by 5.00pm, Friday 5 April 2024. We recommend you read the
            Contest Conditions of Entry prior to completing the entry forms to
            ensure you meet the requirements. For details on terms and
            conditions, outline of group and repertoire requirements or entry
            fees please click{' '}
            <a
              className='underline'
              onClick={(e) => {
                e.preventDefault();
                downloadMultiplePDFs();
              }}
              href='#'>
              here
            </a>
            .
          </p>
        </div>
        <div className='xl:grid grid-cols-2 gap-x-16 mb-[114px] space-y-4 sm:space-y-5 xl:space-y-0'>
          <div className='bg-[#005D7B] rounded-[25px] py-[70px] px-5 md:px-[50px]'>
            <h4 className='blockH4 mb-2'>Instrumental Performance</h4>
            <p className='blockH8 text-sand my-9'>
              Please select this entry type to enter one or more groups to
              compete in the District Rounds of the Contest. You will be
              required to submit details for billing, group personnel and
              performance repertoire.
            </p>
            <Link to='/contest/instrumental-performance' className='btn-entry '>
              Enter now
            </Link>
          </div>
          <div className='bg-[#005D7B] rounded-[25px] py-[70px] px-5 md:px-[50px]'>
            <h4 className='blockH4 mb-2'>Original Composition</h4>
            <p className='blockH8 text-sand my-9'>
              Please select this entry type to enter an original composition for
              the Contest. You will be required to submit details for contact
              information, billing and the title of your composition.
              Information on how to submit your score for adjudication will be
              sent to participants in May. Please complete one entry form per
              composition.
            </p>
            <Link to='/contest/original-composition' className='btn-entry '>
              Enter now
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContestStart;
